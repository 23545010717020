/* Dashboard.css */

@font-face {
    font-family: 'volte';
    src: url('../../fonts/Volte-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'volte';
    src: url('../../fonts/Volte-Medium.ttf') format('truetype');
    font-style: medium;
    font-weight: 500;
}

@font-face {
    font-family: 'volte';
    src: url('../../fonts/Volte-Bold.ttf') format('truetype');
    font-style: bold;
    font-weight: 700;
}

@font-face {
    font-family: 'volte';
    src: url('../../fonts/Volte-Semibold.ttf') format('truetype');
    font-style: semibold;
    font-weight: 600;
}

@font-face {
    font-family: 'volte';
    src: url('../../fonts/Volte-Light.ttf') format('truetype');
    font-style: light;
    font-weight: 300;
}

body {
    background-color: #FBFCFE;
    font-family: volte;
}

.dashboard .pad h1 {
    margin-top: 0;
    font-weight: 500;
    font-family: volte;
    padding-left: 45px;
    padding-top: 60px;
}

.dashboard-data {
    padding: 0 37px;
    width: 94%;
    max-width: 1400px;
}

.dashboard {
    display: flex;
    height: 100vh;
    background-color: #FAFCFE;
}

.sidebar {
    box-shadow: 5px 0 200px rgba(0, 0, 0, 0.05);
}

.base {
    display: flex;
    width: 100%;
    margin-left: 250px;
    width: calc(100% - 255px);
}

h1 {
    font-weight: 500;
    font-family: volte;
}

p {
    font-weight: 500;
}