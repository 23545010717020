/* awards.css */
.baseAwards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f7fa;
    padding: 20px;
}

.awards {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    max-width: 850px;
    width: 100%;
    padding: 40px;
    text-align: center;
}

.awards h1 {
    font-size: 2.5rem;
    margin-bottom: 25px;
    color: #2d3e50;
    font-weight: 600;
}

.price {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.monthly-title, .exclusive-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #88baff;
    font-weight: bold;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    margin-bottom: 40px;
}

/* Styles de podium pour les 3 premiers prix */
.award-item {
    background-color: #f7f9fc;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: transform 0.3s ease;
}

.award-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1e2e3b;
    font-weight: bold;
}

.award-item p {
    font-size: 1.2rem;
    color: #1e2e3b;
}

/* Couleurs discrètes pour le podium */
.podium-first {
    background-color: #f8e292;
    height: 180px;
    width: 150px;
}

.podium-second {
    background-color: #e3e7ed;
    height: 140px;
    width: 150px;
}

.podium-third {
    background-color: #f0e1d3;
    height: 120px;
    width: 150px;
}

.podium-first:hover, .podium-second:hover, .podium-third:hover {
    transform: scale(1.05);
}

/* Prix supplémentaires */
.exclusive-awards {
    display: flex;
    justify-content: space-around;
}

.exclusive-item {
    background-color: #eef1f5;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    width: 40%;
    transition: transform 0.3s ease;
}

.exclusive-item:hover {
    transform: scale(1.05);
}

.exclusive-item h3 {
    font-size: 1.6rem;
    color: #ffc97c;
    font-weight: bold;
}

.exclusive-item p {
    font-size: 1.2rem;
    color: #66707c;
}

/* Réglages responsives */
@media (max-width: 768px) {
    .podium {
        flex-direction: column;
        align-items: center;
    }
    .podium-first, .podium-second, .podium-third {
        width: 100%;
    }
}
