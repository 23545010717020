.box {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin: 25px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
}

.composant {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.settings-container h1 {
    margin-top: 60px;
    margin-left: 45px;
}

.menu-item {
    color: black;
    padding: 10px;
    text-decoration: none;
}