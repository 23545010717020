.Feed {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: volte;
    width: 100%;
}

.Feed h1 {
    font-weight: 500;
    margin-left: 45px;
}

.Feed .title {
    align-items: left;
    width: 100%;
    margin-top: 20px;
}

.content {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Feed .filter-All {
    border-radius: 5px;
    font-family: volte;
    border-color: #6B6B6B;
    width: 125px;
    height: 30px;
    margin-left: 45px;
}

.Feed .view-selection {
    width: 504px;
    text-align: right;
    margin-top: -15px;
}

.Feed .view-selection label {
    color: #6B6B6B;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
}

.Feed .view-selection .feedView {
    font-weight: 600;
    font-family: volte;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    width: 130px;
    background-color: #FBFCFE;
    margin-bottom: 5px;
}

.Feed .post {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 23em;
    
}

.Feed .post iframe {
    margin-bottom: 15px;
    border-radius: 10px;
    border: none;
    max-width: 100%;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
}

.Feed .dropdown-selected {
    background-color: white;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    height: 37px;
    width: 150px;
}

.Feed .dropdown-container {
    max-width: 175px;
    min-width: 110px;
    margin: 0 5px;
    position: relative;
    font-size: 12px;
}

.Feed .dropdown-list.active {
    background-color: white;
}

.selecter-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 40px;
}

.Feed .dropdown-search {
    width: 85%;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 12px;
    padding: 10%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.Feed .dropdown-selected svg {
    margin-top: -2px;
}

.post-container {
    position: relative;
    margin-bottom: 20px;
}

.post-location {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 1;
    font-size: 14px;
}

.Feed .impression-counter {
    position: absolute;
    bottom: 23px;
    right: 16px;
    background-color: rgba(255, 255, 255);
    padding: 5px 10px;
    border-radius: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    z-index: 1000;
    color: #0377fd;
}