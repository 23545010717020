.ToggleSwitch .toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 35px;
}

.ToggleSwitch .toggle-switch input[type="checkbox"] {
    display: none;
}

.ToggleSwitch .toggle-switch label {
    position: relative;
    display: inline-block;
    width: 40px;
    /* Réduire la largeur */
    height: 20px;
    /* Réduire la hauteur */
    background-color: #B3CBE8;
    border-radius: 20px;
    /* Ajuster le rayon pour correspondre à la nouvelle hauteur */
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
}

.ToggleSwitch .toggle-switch p {
    justify-items: center;
    align-items: center;
}

.ToggleSwitch .toggle-switch label::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    /* Réduire la largeur du cercle */
    height: 16px;
    /* Réduire la hauteur du cercle */
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.ToggleSwitch .toggle-switch input[type="checkbox"]:checked+label {
    background-color: #0177FD;
}

.ToggleSwitch .toggle-switch input[type="checkbox"]:checked+label::after {
    transform: translateX(20px);
    /* Ajuster la translation pour correspondre à la nouvelle largeur */
}

.ToggleSwitch .settings-box {
    display: flex;
    flex-direction: row;
}