.library-s {
    display: flex; /* Mettez flex pour aligner les enfants côte à côte */
    font-family: volte;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow: hidden;
}

.library-s .sidebar-ly {
    width: 200px; /* Sidebar avec une largeur fixe */
    background-color: white;
    font-size: 16px;
    padding: 10px;
    width: 200px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    overflow-y: auto;
}

.library-s .folder {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.library-s .folder:hover {
    background-color: #e0e0e0;
}

.library-s .folder.active {
    background-color: #1976d2;
    color: white;
}

.library-s .main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.library-s .file-view {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px;
    background-color: white;
    overflow-y: auto;
    min-width: 400px; /* Largeur minimum pour éviter l'effondrement */
}

.library-s .file-preview {
    width: 400px; /* Largeur fixe de la prévisualisation */
    margin-left: 20px; /* Séparation entre file-view et preview */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.library-s .file-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px;
    width: 100%;
}

.library-s .file-item {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    height: 300px;
    display: flex;
    flex-direction: column;
    background: white;
}

.library-s .file-item:hover {
    transform: scale(1.02);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.library-s .file-item.selected {
    border-color: #1976d2;
    box-shadow: 0 0 5px rgba(25, 118, 210, 0.5);
}

.library-s .file-image-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.library-s .file-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.library-s .file-pdf {
    height: 100%;
    width: 100%;
    position: relative;
}

.library-s .pdf-preview {
    width: 100%;
    height: 100%;
}

.library-s .expandPdf {
    position: absolute;
    top: 1em;
    right: 2em;
    margin: 0 !important;
    width: 3em !important;
    height: 3em !important;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    z-index: 10;
    cursor: pointer;
}

.library-s .download-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
}

.library-s .download-btn:hover {
    background-color: #f0f0f0;
}

.library-s .controls {
    padding: 10px;
    display: flex;
    align-items: flex-end;
}

.library-s .controls button:disabled {
    cursor: not-allowed;
}

.library-s .add-image-button {
    padding: 5px 10px;
    justify-items: center;
    align-items: center;
    height: 15px;
    font-size: 12px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.library-s .image-preview img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
