.menu-container {
    display: flex;
    width: 635px;
    justify-content: space-around;
    margin-left: 60px;
    background-color: #F6F6F6;
    padding: 2px;
    border-radius: 8px;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.05);
}

.menu-container .menu-item {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 500;
}

.menu-container .menu-item:hover,
.menu-container .menu-item.active {
    background-color: white;
}

.menu-container .menu {
    position: relative;
}

.menu-container .menu-item {
    position: relative;
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}