.chartjs-tooltip {
    width: 150px;
    opacity: 0;
    transition: all 0.1s ease;
    pointer-events: none;
    transform: translate(-50%, -100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    /* Ombre */
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    z-index: 100;
}

.chartjs-tooltip .tooltip-title {
    font-size: 12px;
    font-weight: 800;
    color: #74C8EF;
    margin-bottom: 10px;
}

.chartjs-tooltip .tooltip-body {
    font-size: 10px;
    font-weight: 700;
    color: #333;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.chartjs-tooltip::after {
    content: '';
    position: absolute;
    bottom: -10px;
    /* Position de la flèche */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    /* Couleur de la flèche */
    z-index: -1;
}

.dropdown-container {
    max-width: 170px;
    margin: 0 25px;
    position: relative;
}

.dropdown-selected {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-weight: 400;
}

.dropdown-selected svg {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
}

.dropdown-selected.active {
    border-radius: 5px 5px 0 0;
    border: 1px solid #0177fd74;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease;
    z-index: 1;
}

.dropdown-list.active {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 0 0 5px 5px;
    border: 1px solid #0177fd74;
    border-top: none;
}

.dropdown-list div {
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 400;
}

.dropdown-list div:hover {
    background: #9fd9f447;
}

.dropdown-search {
    width: 150px;
    padding: 10px;
    margin: 10px 9px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    font-weight: 450;
}

.dropdown-selected svg {
    margin-top: 2px;
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
}

.dropdown-selected.active svg {
    transform: rotate(180deg);
}