.feedList ul {
    list-style: none;
    padding: 0;
    font-weight: 500;
    width: 650px;
}

.feedList li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 0.5px solid #D9D9D9;
    font-weight: 500;
}

.feedList li:hover {
    color: #0177FD;
}

.feedList input[type="checkbox"] {
    margin-right: 10px;
}

.feedList button {
    margin-left: 10px;
}

.feedList .toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.feedList .preview {
    margin-top: 10px;
    background: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
}

.feedList iframe {
    width: 100%;
    height: 300px;
    border: none;
}

.feedList .selected {
    background-color: #e8f0fe;
}

.feedList {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
    margin: 25px;
}

.feedList .add-button {
    border: none;
    background-color: white;
    font-size: 25px;
}

.feedList .delete-button {
    border: none;
    background-color: white;
    font-size: 12px;
}