/* Tooltip container */

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position the tooltip above the link */
    left: 50%;
    margin-left: -100px;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}


/* Other CSS rules from the previous example */

.login-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.login-image {
    flex: 1;
    display: flex;
    align-items: center;
    max-height: 100vh;
    justify-content: center;
    background-color: #eef2f6;
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-box {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background-color: #ffffff;
}

.login-content {
    max-width: 400px;
    width: 100%;
}

.login-content h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333333;
}

.login-content p {
    margin-bottom: 30px;
    color: #666666;
    font-size: 16px;
}

.login-input {
    width: 367px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.remember-me {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555555;
}

.remember-me input[type="checkbox"] {
    margin-right: 10px;
}

.forgot-password {
    font-size: 14px;
    color: #1976d2;
    text-decoration: none;
}

.forgot-password:hover {
    text-decoration: underline;
}

.login-button {
    width: 100%;
    padding: 15px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #005bb5;
}

.error-message {
    color: red !important;
    margin-top: 10px;
    font-size: 14px;
}

.login-box img {
    width: 200px;
    margin-bottom: 20px;
}

.verification-box {
    max-width: 500px !important;
    padding: 40px !important;
}

.verification-box img {
    margin-bottom: 30px;
}