.tips {
    width: 100%;
    font-family: volte;
}

.tips h1 {
    margin-top: 60px;
}

.align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tips .box-tips {
    background-color: white;
    width: 970px;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
}

.tips h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 25px;
    font-weight: 500;
}

.tips .question {
    font-size: 22px;
    font-weight: 500;
}

.tips ul {
    list-style-type: none;
    padding: 0;
}

.tips .title {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 45px;
}

.tips .question-item {
    margin-top: 20px;
}

.tips .question {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    transition: background 0.3s ease;
}

.tips .toggle-button {
    position: relative;
    width: 12px;
    height: 12px;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.tips .toggle-button::before,
.tips .toggle-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2.5px;
    background-color: black;
    transition: transform 0.3s ease;
}

.tips .toggle-button::before {
    transform: translate(-50%, -50%) rotate(0deg);
}

.tips .toggle-button::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.tips .toggle-button.active::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.tips .toggle-button.active::after {
    transform: translate(-50%, -50%) rotate(135deg);
}

.tips .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    margin-top: 10px;
    font-size: 12px;
    width: 875px;
}

.tips .answer.visible {
    max-height: 350px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    /* Ajustez cette valeur en fonction de la hauteur de votre contenu */
    opacity: 1;
}

.tips hr {
    border: 0;
    border-top: 0.5px solid #A5B4CB;
}

.feedList {
    min-width: 500px;
}

.edit-button {
    background-color: #3476F5;
    /* Green background */
    border: none;
    /* Remove borders */
    color: white;
    /* White text */
    padding: 5px 10px;
    /* Some padding */
    text-align: center;
    /* Centered text */
    text-decoration: none;
    /* Remove underline */
    display: inline-block;
    /* Make the button inline-block */
    font-size: 16px;
    /* Increase font size */
    margin: 4px 2px;
    /* Some margin */
    cursor: pointer;
    /* Pointer/hand icon */
    border-radius: 12px;
    /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease;
    /* Smooth transitions */
}

.edit-button:hover {
    background-color: #3476F5;
    /* Darker green on hover */
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}