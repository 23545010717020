.iframe-container {
    width: 80%;
    height: 80%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.library {
    width: 100%;
}

.library h1 {
    margin-top: 60px;
    margin-left: 45px;
    text-align: left;
    margin-bottom: 20px;
}

.library label {
    align-content: center;
    font-family: volte;
    font-size: 16px;
}

.library .select-tag {
    display: flex;
    flex-direction: row;
    margin-left: 45px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-family: volte;
}

.library select {
    margin-left: 10px;
    padding: 5px;
    font-family: volte;
    font-size: 16px;
}

.library .photo-grid {
    width: 80%;
    margin: 25px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-auto-rows: 200px;
    gap: 15px;
}

.library .photo {
    width: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.library .photo img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.library .photo:hover {
    transform: scale(1.02);
}

.library .photo:hover img {
    transform: scale(1);
}

.library .photo.small {
    grid-row: span 1;
}

.library .photo.medium {
    grid-row: span 2;
}

.library .photo.large {
    grid-row: span 3;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreen img {
    width: 90%;
    height: 90vh;
    border-radius: 10px;
}

.fullscreen embed{
    width: 1200px;
    height: 100%;
}

.download-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
}

.download-btn:hover {
    background-color: #f0f0f0;
}

.library .dropdown-selected {
    height: 24px;
    width: 150px;
    font-size: 12px;
    align-items: center;
}

.library .dropdown-list div {
    font-size: 12px;
    background-color: white;
}

.library .tags {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    z-index: 10;
}

.library .tag {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    z-index: 10;
}

.library .file-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
    padding: 20px;
    width: 100%;
}

.library .file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px;
    height: 500px;
    overflow: hidden;
}

.library .file-item .file-image-wrapper {
    position: relative;
    height: 300px;
    text-align: center;
    margin-bottom: 10px;
}

.library .file-item img {
    height: 100%;
    object-fit: cover;
    transition: .2s;
}

.library .file-item img:hover {
    filter: brightness(90%);
    transition: .1s;
}

.library .file-item .file-pdf {
    height: 300px;
    width: 100%;
}

.library .file-item .file-pdf iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.library .file-item .pdf-preview {
    position: relative;
    width: 100%;
    height: 100%;
}

.library .file-item .file-video {
    width: 100%;
    height: auto;
}

.library .file-name {
    font-size: 16px;
    margin: 10px 0;
    font-weight: 500;
    color: #333;
}

.library .tags {
    margin: 5px 0;
}

.library .associated-posts {
    position: relative;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 12px;

    max-height: 120px;
    overflow-y: auto;
    z-index: 1;
}

.library .associated-posts h4 {
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    position: sticky;
    top: 0;
    background: #f8f9fa;
    z-index: 2;
}

.library .post-links {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.library .post-link {
    color: #2196F3;
    text-decoration: none;
    font-size: 13px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
    background-color: transparent;
    line-height: 1.4;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.library .post-link:hover {
    background-color: rgba(33, 150, 243, 0.1);
    color: #1976D2;
}

.expandPdf {
    position: absolute;
    top: 1em;
    right: 2em;
    margin: 0 !important;
    width: 3em !important;
    height: 3em !important;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
    z-index: 10;
    cursor: pointer;
}