    .ranking {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .ranking .title {
        width: 25%;
        text-align: center;
        margin-top: 2px;
    }
    
    .ranking .linkedinButton {
        width: 5%;
        text-align: center;
        margin-top: 2px;
    }
    
    .ranking .location {
        width: 15%;
        text-align: center;
        margin-top: 2px;
    }
    
    .ranking .nbPostsContent {
        width: 10%;
        text-align: center;
        margin-top: 2px;
    }
    
    .ranking .score {
        width: 10%;
        text-align: center;
        margin-top: 2px;
    }
    
    .ranking h1 {
        margin-left: 45px;
        margin-top: 60px;
        margin-bottom: 10px;
    }
    
    .rankingItem {
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 10px;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        align-items: center;
        justify-content: start;
    }
    
    .ranking .profile {
        display: flex;
        align-items: center;
        height: 100%;
        width: 25%;
    }
    
    .profile img {
        width: 40px;
        /* Set according to your preference */
        height: 40px;
        border-radius: 20px;
        margin-right: 10px;
    }
    
    .profile div span {
        display: block;
    }
    
    .change {
        font-size: 12px;
    }
    
    .positive {
        color: green;
    }
    
    .negative {
        color: red;
    }
    
    .neutral {
        color: orange;
    }
    
    .ranking .rankings {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
    
    .ranking .rankingItem .name_flag {
        display: flex;
        align-items: center;
        height: 100%;
        margin-top: 2px;
    }
    
    .ranking .rankingItem .name_flag .name {
        margin: auto 7px;
        align-items: center;
        justify-items: center;
    }
    
    .ranking .rankingItem .name_flag p {
        display: flex;
        height: 100%;
        margin-top: 3px;
    }
    
    .ranking .rank {
        display: flex;
        width: 10%;
        align-items: center;
        justify-content: center;
        
    }
    
    .ranking .rank .rankNumber {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        width: 50px;
        height: 30px;
        border-radius: 5px;
        background-color: #E9F1FD;
        margin-right: 10px;
    }
    
    .ranking .title-desc {
        display: flex;
        flex-direction: row;
        color: #A7B4C9;
        width: 100%;
        margin: 0 auto;
        align-items: center;
        /* Vertically center the items */
        padding: 10px;
        /* Adds spacing */
    }
    
    .ranking .title-desc h4 {
        font-weight: 600;
        margin: 0;
        line-height: 1.5;
        font-size: 1em;
        height: 100%;
        display: flex;
        align-items: center;
        /* Vertically centers text */
    }
    
    .ranking .title-desc .name {
        width: 25%;
        /* Adjusted width for better alignment */
        justify-content: flex-start;
    }
    
    .ranking .title-desc .profil {
        width: 5%;
        justify-content: center;
        /* Center the icon */
    }
    
    .ranking .title-desc .posts {
        width: 25%;
        justify-content: center;
        /* Center the text */
    }
    
    .ranking .title-desc .enthie {
        width: 15%;
        justify-content: center;
        /* Center the text */
    }
    
    .ranking .title-desc .nbPosts {
        width: 10%;
        
        justify-content: center;
        /* Center the text */
    }
    
    .ranking .title-desc .scores {
        width: 10%;
        justify-content: center;
        /* Center the text */
    }
    
    .ranking .title-desc .rank {
        width: 10%;
        display: flex;
        margin-right: 0px;
        /* Adjusted width for better alignment */
        justify-content: center;
    }
    
    .group-filter {
        display: flex;
        flex-direction: row;
        margin: 10px 25px;
    }
    
    .group-filter .filter {
        font-family: volte;
        border-radius: 5px;
        border-color: #D9D9D9;
        font-weight: 500;
        width: 100px;
        height: 30px;
        margin-right: 10px;
    }
    
    .ranking .first-place {
        border: #F9E18F 2px solid;
    }
    
    .ranking .second-place {
        border: #C7C9E3 2px solid;
    }
    
    .ranking .third-place {
        border: #C9B2A3 2px solid;
    }
    
    .ranking .dropdown-selected {
        background-color: white;
        font-size: 12px;
        height: 37px;
    }
    
    .ranking .dropdown-container {
        width: 130px;
        height: 35px;
        margin: auto 5px;
        margin-bottom: 25px;
        margin-top: 0;
        position: relative;
        font-size: 12px;
    }
    
    .ranking .dropdown-list.active {
        background-color: white;
    }
    
    .ranking .dropdown-search {
        width: 85%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 12px;
        padding: 10%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: start;
    }
    
    .rankingItem:nth-child(odd) {
        background-color: #ffffff;
    }
    
    .rankingItem:nth-child(even) {
        background-color: #FAFAFA;
    }
    
    .linkedinIcon {
        width: 15px;
        height: 15px;
    }
    
    .ranking .dropdown-selected svg {
        margin-top: -2px;
    }