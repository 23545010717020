.dashboard {
    display: flex;
    font-family: volte;
    flex-direction: column;
    width: 100%;
}

.dashboard .haeder-stat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dashboard .box-box {
    display: flex;
    flex-direction: row;
    background-color: #F2F6F9;
    border-radius: 5px;
    width: 100px;
    height: 30px;
}

.dashboard .box-box .value {
    color: #4F4F4F;
    font-weight: 600;
    font-size: 23px;
    margin-right: 10px;
    margin-left: 9px;
}

.tooltip-body {
    font-size: 15px;
}

.dashboard .label {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
}

.dashboard .infoDisplay {
    display: flex;
    width: 100%;
    height: 100px;
    margin: auto 10px;
    justify-self: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
}

.dashboard .infoDisplay .data {
    margin: 10px 20px;
}

.dashboard .progress-bar {
    display: flex;
    flex-direction: row;
    min-width: 200px;
}

.dashboard .progress-bar .nb-post {
    margin: auto;
    font-size: 1.2em;
    margin-left: 5px;
    font-weight: 600;
}

.infoDisplay .evolution {
    margin: auto;
    padding-top: 2px;
    padding-right: 4px;
    font-size: 10px;
}

.infoDisplay .partic {
    margin: auto;
    margin-top: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #B5BDC5;
}

.custom-select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    /* for Safari */
    -moz-appearance: none;
    /* for Firefox */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw2IDYgMTEgMSIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIuIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
    box-shadow: none;
    /* Removes unwanted shadow */
}

.custom-select option {
    padding: 10px;
    background-color: white;
    color: #333;
}

.custom-select:focus {
    border-color: #007bff;
    /* Blue outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Soft blue shadow */
    outline: none;
    /* Remove default outline */
}

.graphe-stat {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
    padding: 35px;
    margin: 10px;
    height: 480px;
    /* Utiliser toute la largeur disponible */
}

.graphe-stat .marge {
    padding: 10px 20px;
    max-height: 20%;
}

.select-boxFlex {
    align-items: start;
    justify-self: start;
}