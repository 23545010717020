.user .user-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    height: 400px;
    overflow-y: auto;
}

.user h2 {
    font-weight: 700;
    text-align: center;
}

.user .user-item {
    cursor: pointer;
    border-bottom: #ccc 0.5px solid;
    width: 600px;
    padding: 3px 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 500;
}

.user .user-item div {
    margin-left: 10px;
}

.user .modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal {
    display: flex; /* ou block */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}
.modal-content {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    height: 40vh;
}

.user form {
    display: flex;
    flex-direction: column;
    font-weight: 500;
}

.user input[type="text"] {
    margin-bottom: 10px;
}


.user-item img {
    width: 30px;
    /* Ajuste la taille de l'image, tu peux modifier cette valeur pour la rendre plus petite */
    height: 30px;
    /* La hauteur doit être égale à la largeur pour que l'image reste proportionnelle */
    border-radius: 50%;
    /* Cette règle rend l'image ronde */
    object-fit: cover;
    /* Cette règle assure que l'image s'adapte bien à l'intérieur de son conteneur sans être déformée */
    /* Optionnel : ajoute une bordure fine autour de l'image */
    margin-bottom: 10px;
    /* Optionnel : espace entre l'image et le reste du contenu */
}