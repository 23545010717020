/* Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    font-family: volte;
    /* Assurez-vous que la barre latérale prend toute la hauteur de la page */
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
}

.sidebar .logoEsk {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sidebar .profile {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

.sidebar .profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
}

.sidebar img {
    width: 100px;
    height: auto;
    margin: 10px 0;
}

.sidebar .menu {
    width: 100%;
    list-style: none;
    padding: 0;
    position: relative;
}

.sidebar .menu li {
    position: relative;
    margin-bottom: 5px;
    padding-left: 27px;
}

.sidebar .menu li button {
    height: 45px;
    width: 200px;
    background: none;
    border: none;
    color: black;
    text-align: center;
    padding: 15px 20px;
    font-size: 17px;
    font-weight: 500;
    font-family: volte;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.sidebar .menu li button:hover,
.sidebar .menu li button.active {
    background-color: #E7F1FE;
    border-radius: 8px;
    color: #0177FD;
    padding-left: 25px;
    /* Adjusted padding for indicator spacing */
}

.sidebar .menu li button .icon {
    margin-right: 10px;
    padding-bottom: 3px;
    border-color: #A7B4C9;
}

.sidebar .settings {
    margin-top: auto;
    padding-bottom: 20px;
    width: 100%;
}

.sidebar .settings button {
    background: none;
    border: none;
    color: black;
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    font-size: 16px;
    margin-left: 27px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
}

.sidebar .settings button .icon {
    margin-right: 10px;
}


/* Updated styles for the indicator */

.sidebar .indicator {
    position: absolute;
    left: 0;
    width: 4px;
    height: 45px;
    background-color: #0177FD;
    border-radius: 4px;
    transition: top 0.3s ease;
}

.profile p {
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin: auto 5px;
}